

















































































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {VTextField} from 'vuetify/lib/components';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'uOttawa1321Lab3Procedure',
  components: {StembleLatex, STextarea, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        procedure: null,
        observations: null,
        language: 'en',
      },
      questions: [
        {
          en: 'This section should briefly summarize what YOU actually did - not what is stated in the manual. The procedure may be listed in point form or in paragraph form. Your response should not exceed 250-350 words.',
          fr: 'Cette section doit décrire ce que VOUS avez réellement fait et observé, pas nécessairement ce qui est indiqué dans le manuel. Votre réponse ne doit pas dépasser 250 à 350 mots.',
        },
        {
          en: 'Report all observations as they occurred - not as described for the average experiment in this manual. You should include the visual appearance of reagents (e.g., pale yellow liquid, colorless crystals, white powder) and any observations made during the reaction (colour change, bubbles, smells, etc.). Your response should not exceed 200-300 words.',
          fr: "Signalez toutes les observations telles qu'elles se sont produites - non pas comme décrit pour l'expérience moyenne dans ce manuel. Vous devez inclure l'apparence visuelle des réactifs (par exemple, liquide jaune pâle, cristaux incolores, poudre blanche) et toutes les observations faites pendant la réaction (changement de couleur, formation de bulles, odeurs, etc.). Votre réponse ne doit pas dépasser 200 à 300 mots.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
